import {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alpha,
  alphaNum,
  numeric,
  integer,
  decimal,
  email,
  sameAs,
  url,
} from '@vuelidate/validators'

export default {
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  alpha,
  alphaNum,
  numeric,
  integer,
  decimal,
  email,
  sameAs,
  url,
}
